import { connect } from "react-redux";
import compose from "recompose/compose";
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import { toggleFilterPanel } from '../../../../redux/actions/actions';

import PageFilter from "./PageFilter";

export function getValidationSchema() {
    return Yup.object().shape({
        q: Yup.string().max(65, 'Maksymalnie 65 znaków').nullable(),
        functions: Yup.array(),
        trades: Yup.array(),
        appearances: Yup.array()
    });
}

export const initialValues = {
    q: '',
    functions: [],
    trades: [],
    appearances: [],
}

const mapStateToProps = state => {
  return {
    filterPanelIsOpen: state.references.filterPanelIsOpen,
  }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        toggleFilterPanel,
    }, 
    dispatch);
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageFilter);