import React from 'react';
import PropTypes from 'prop-types';

class FormikTextField extends React.PureComponent {

  static propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    field: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    form: PropTypes.shape({
      dirty: PropTypes.bool,
      errors: PropTypes.object,
      isSubmitting: PropTypes.bool
    }),
    trim: PropTypes.string,
    onChange: PropTypes.func
  };
  
  static defaultProps = {
    label: null,
    disabled: false,
    onChange: (e) => {}
  };

  handleBlur = e => {
    const {
      field: { onBlur },
    } = this.props;
    onBlur(e)
  }

  handleChange = e => {
    const { form, field, trim, onChange } = this.props;
    const { setFieldValue } = form;
    const value = trim ? e.target.value.trim() : e.target.value;
    setFieldValue(field.name, value);

    onChange(e)
  }

  render() {
    const {
      field: { value },
      form: { isSubmitting },
      disabled,
    } = this.props;

    return (
      <div>
        <input
            id="search_input"
            type="text" 
            placeholder="Search keyword"
            disabled={isSubmitting || disabled}
            value={value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
        />
      </div>
    );
  };
};

export default FormikTextField;