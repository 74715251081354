import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import css from './FormikCheckBoxField.module.scss';

const FormikCheckBoxField = (props) => {
  const isChecked = function(value){
    return value || false;
  };

  const {
    id,
    children,
    field: { onBlur, onChange },
    field,
    label,
    disabled,
    form: { touched, errors, isSubmitting },
  } = props;

  const errorText = errors[field.name];
  const touch = getIn(touched, field.name);
  const hasError = touch && errorText !== undefined;

  const checked = isChecked(field.value);

  return (
    <div className={css.componentContent}>
        <input 
            type="checkbox" 
            id={id}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            className={css.checkbox}
            data-checked={checked}
        />
        <label
            htmlFor={id}
            className={css.checkboxLabel}
            disabled={isSubmitting || disabled}
        >
            <div className={css.labelWrapper}>
              <div className={css.icon}>
              </div>
              <div className={css.label}>
              {label}
              </div>
            </div>
        </label>
        <div>
            {children}
            {hasError ? <div>{errorText}</div> : null}
        </div>
    </div>
  );
};

FormikCheckBoxField.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool
  }),
  disabled: PropTypes.bool,
  createLabel: PropTypes.func,
};

FormikCheckBoxField.defaultProps = {
  children: '',
  disabled: false,
};

export default FormikCheckBoxField;
