import {
  GET_REFERENCES,
  GET_MANIFEST,
  GET_REFERENCES_BY_FILTER,
  OPEN_FILTER_PANEL,
  CLOSE_FILTER_PANEL,
  TOGGLE_FILTER_PANEL,
} from "./actionTypes";

export const getReferences = () => {
  return {
    type: GET_REFERENCES,
    payload: {
      client: "local",
      request: {
        method: "GET",
        url: "manifest.json",
      }
    }
  };
};

export const getManifest = () => {
    return {
        type: GET_MANIFEST,
        payload: {
            request: {
                method: "GET",
                url: "manifest.json",
                cache: false
            }
        }
    };
};

export const getReferencesByFilter = (filters) => {
    return {
        type: GET_REFERENCES_BY_FILTER,
        payload: {
            client: "references",
            request: {
                method: "GET",
                url: "get_references",
            },
            params: {
                filters
            }
        }
    };
};

export const openFilterPanel = () => {
  return {
      type: OPEN_FILTER_PANEL,
      payload: {}
  };
};

export const closeFilterPanel = () => {
  return {
      type: CLOSE_FILTER_PANEL,
      payload: {}
  };
};

export const toggleFilterPanel = () => {
  return {
      type: TOGGLE_FILTER_PANEL,
      payload: {}
  };
};

