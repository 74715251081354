// eslint-disable-next-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from "./header/Header";
import Footer from "./footer/Footer";
import TopBar from "./top-bar/TopBar";

import css from './DefaultLayout.module.scss';

class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.element,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    return (
      <div className={css.layoutWrapper}>
        <TopBar />
        <Header />
        <div className={css.contentWrapper}>
          <div className={css.content}>
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default DefaultLayout
