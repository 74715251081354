import produce from "immer";
import {
  GET_MANIFEST_SUCCESS,
} from "../actions/actionTypes";

import { sortByParamsArray } from '../../utils/data/data';

const initialState = {
  data: {
    functionOptions: [], 
    tradesOptions: [],  
    appearancesOptions: [],
  },
};

export default (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case GET_MANIFEST_SUCCESS: {

              const manifest = action.payload.data;
              const { references = [] } = manifest;

              let { functionOptions, tradesOptions, appearancesOptions } = manifest;

              functionOptions = functionOptions.map(option => {
                const { value } = option;
                const tags = references.filter(({ tags_function }) => tags_function.includes(value));
                return { ...option, count: tags.length }
              });

              tradesOptions = tradesOptions.map(option => {
                const { value } = option;
                const tags = references.filter(({ tags_trade }) => tags_trade.includes(value));
                return { ...option, count: tags.length }
              });

              appearancesOptions = appearancesOptions.map(option => {
                const { value } = option;
                const tags = references.filter(({ tags_appearance }) => tags_appearance.includes(value));
                return { ...option, count: tags.length }
              });

              draft.data = { 
                ...manifest, 
                functionOptions: sortByParamsArray(functionOptions, [{ name: 'label' }]), 
                tradesOptions: sortByParamsArray(tradesOptions, [{ name: 'label' }]), 
                appearancesOptions: sortByParamsArray(appearancesOptions, [{ name: 'label' }]), 
              }
              break;
            }
            default:
                break;
        }
  });