import React, { Component } from "react";
import creature from "../../../../../img/creatue_small.png";
import baner from "../../../../../img/baner.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./Header.css";

export class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="header-content">
          <div className="creature-wrapper">
            <div>
              <img className="creature-image" src={creature} alt="creature" />
            </div>
            <div>
              <div className="banner-wrapper">
                <div>
                  <img className="banner-image" src={baner} alt="baner" />
                </div>
                <div className="header-title">
                  <div>Inspiruj się </div>
                  <div>pomysłami Rich Media!</div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-description">
            <p>Reklama internetowa nie musi być nudna!</p>
            <p>
              Korzystaj z szeregu funkcjonalności, które przydadzą Ci się w
              świecie reklamy!
            </p>
            <p>Filtruj na bazie funkcjonalności oraz grupy docelowej.</p>
          </div>
          <div>
            <div className="x">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
