import produce from "immer";
import uuid from 'react-uuid';
import {
  GET_MANIFEST_SUCCESS,
  GET_REFERENCES_BY_FILTER_SUCCESS,
  TOGGLE_FILTER_PANEL,
} from "../actions/actionTypes";

const initialState = {
  references: [],
  filter_references: [],
  filterPanelIsOpen: false,
};

export default (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case GET_MANIFEST_SUCCESS: {

                const { references = [] } = action.payload.data;
                const newReferences =  references.map(reference => {
                  return { ...reference, id: uuid() }
                })

                draft.references = newReferences;
                draft.filter_references = newReferences;
                break;
            }
            case GET_REFERENCES_BY_FILTER_SUCCESS: {
              draft.filter_references = action.payload.data;
              break;
            }
            case TOGGLE_FILTER_PANEL: {
              draft.filterPanelIsOpen = !state.filterPanelIsOpen
              break;
            }
            default:
                break;
        }
  });