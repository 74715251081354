import React, { Component } from "react";
import logo from "../../../../../img/logo.png";

import "./TopBar.css";

class TopBar extends Component {
  render() {
    return (
      <div className="top-bar-wrapper">
        <div className="top-bar">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <h1>Trzask Rich Labs</h1>
        </div>
      </div>
    );
  }
}

export default TopBar;
