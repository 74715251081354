class ReferencesClient {
  constructor(params = {}) {
    this.store = params.store;
  }

  setStore(store) {
    this.store = store;
  }

  searchValueExist(val, str) {
    const valArray = val.trim().toLowerCase().split(' ');
    const filterValues = valArray.filter(value => {
      const exist = str.trim().toLowerCase().includes(value);
      return exist;
    });
    return filterValues.length;
  }

  get(url, params = {}, dasds) {
    const state = this.store.getState();
    const references = state.references.references || [];
    const { filters } = params;

    switch (url) {
      case 'get_references':

        const filter_references = references.filter(reference => {
          const {
            title,
            tags_function,
            tags_trade,
            tags_appearance
          } = reference;

          return (filters.functions.length ? tags_function.split(', ').some(v=> filters.functions.indexOf(v) !== -1) : true) &&
                 (filters.trades.length ? tags_trade.split(', ').some(v=> filters.trades.indexOf(v) !== -1) : true) &&
                 (filters.appearances.length ? tags_appearance.split(', ').some(v=> filters.appearances.indexOf(v) !== -1) : true) && 
                 ((filters.q !== '' ? (title.toLowerCase().includes(filters.q.trim().toLowerCase())) : true) || 
                    this.searchValueExist(filters.q, tags_function) ||
                    this.searchValueExist(filters.q, tags_trade) ||
                    this.searchValueExist(filters.q, tags_appearance))
        });

        return Promise.resolve({
            data: filter_references
        })
      default:
        return Promise.reject({ data: {} });
    }
  }

  post(url) {
    return Promise.resolve({ data: {} });
  }

  put(url, params) {
    return Promise.resolve({ data: {} });
  }

  delete(url, params) {
    return Promise.resolve({ data: {} });
  }

  request(request) {
    if (!request) {
      return Promise.reject({ data: {} });
    }

    const { method, url, reduxSourceAction } = request;
    const { payload: { params, data } } = reduxSourceAction;

    switch (method) {
      case 'GET':
       
        return this.get(url, params, reduxSourceAction);
      case 'POST':
        return this.post(url, data, reduxSourceAction);
      case 'PUT':
        return this.put(url, data, reduxSourceAction);
      case 'DELETE':
        return this.delete(url, data, reduxSourceAction);
      default:
        return Promise.reject({ data: {}, reduxSourceAction });
    }
  }
}

export default ReferencesClient;
