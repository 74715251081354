import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import Hashtags from "./hashtags/Hashtags";
import cup from "../../../../../../../img/Grupa.png";
import main from "../../../../../../../img/main-bg.png";
import aside from "../../../../../../../img/aside-bg.png";

import "./Item.css";

class Item extends Component {

  static propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    title: PropTypes.string,
    tags_function: PropTypes.string,
    tags_trade: PropTypes.string,
    tags_appearance: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    image_out: PropTypes.string,
    image_over: PropTypes.string,
    active: PropTypes.bool,
    changeActive: PropTypes.func,
  }

  static defaultProps = {
    id: null,
    title: null,
    tags_function: null,
    tags_trade: null,
    tags_appearance: null,
    description: null,
    link: null,
    image_out: null,
    image_over: null,
    active: false,
    changeActive: () => {},
  }

  state = {};

  changeActive = () => {
    if (isMobile) {
      const { changeActive, id, active } = this.props;
      changeActive(id, !active)
    }
  };

  render() {
    return (
      <div className="item" onClick={this.changeActive}>
        {this.createComponentContent()}
      </div>
    );
  }

  createComponentContent() {
    const { 
      index,
      active,
      tags_function,
      tags_trade,
      tags_appearance,
      image_over,
      // image_out,
    } = this.props;

    const imageOver = image_over !== null && image_over !== undefined && image_over !== '' ? image_over : cup;

    return (
      <div 
        className={classNames({ 'item-content': true }, { 'item-content-active-mobile': isMobile }, { 'item-content-active-desctop': !isMobile })}
        onClick={this.changeActive} 
        data-active={active}
        >
        <div className="item-content-wrapper padding-15">
          <div className="number">{`#${index + 1}`}</div>
          <div className="main-text">{this.props.title}</div>
          <img className="main-image" src={main} alt="" />
          <img className="aside" src={aside} alt="" />
          <div>
            <Hashtags 
              className="hashtags"
              tags_function={tags_function}
              tags_trade={tags_trade}
              tags_appearance={tags_appearance}
            />
          </div>
        </div>
        <div className="hover-after-image-wrapper">
          <img className="image-over" src={imageOver} alt="" />
        </div>
        <div className="item-before-wrapper padding-15" onClick={this.changeActive}>
          <div className="item-description">
            {this.props.description}
          </div>
        </div>
      </div>
    );
   
  }
}

export default Item;
