import React, { Component } from "react";
import PropTypes from "prop-types";
import Item from "./list/item/Item/Item";

import PageFilter from "../../page/filter/PageFilter.container";
import "../../page/filter/PageFilter.css";

import css from "./ReferencesIndexPage.module.scss";

class ReferencesIndexPage extends Component {
    static propTypes = {
        getManifest: PropTypes.func,
        references: PropTypes.array,
        filterPanelIsOpen: PropTypes.bool
    };

    static defaultProps = {
        filterPanelIsOpen: false,
    }

    state = {
        selectedItems: {}
    };

    componentDidMount() {

        const { getManifest } = this.props;
        getManifest();
    }

    render() {
        const { filterPanelIsOpen, filter_references } = this.props;

        return (
            <div>
                <div 
                    className={css.searchWrapper}
                    data-mask={filterPanelIsOpen}
                >
                    {this.createSearchSection()}
                </div>
                <div>
                    <div 
                        className={css.listContainer}
                        data-mask={filter_references.length && filterPanelIsOpen}
                    >
                        <div 
                            className={css.listWrapper}
                            data-mask={filterPanelIsOpen}
                        >
                            {this.createListSection()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createListSection() {
        const { filter_references = [] } = this.props;
        return filter_references.map(this.createListItem, this);
    }

    changeActive = (id, value) => {
        const { selectedItems } = this.state;
        const isActive = selectedItems[id] || false;

        if (isActive) {
            this.setState({ selectedItems: {} });
        } else {
            this.setState({ selectedItems: { [id]: value } });
        }
    }

    createListItem(item, index) {
        const { id } = item;

        const { selectedItems } = this.state;
        const active = selectedItems[id] || false;  

        return (
            <Item
                key={id}
                id={id}
                index={index}
                title={item.title}
                tags_function={item.tags_function}
                tags_trade={item.tags_trade}
                tags_appearance={item.tags_appearance}
                description={item.description}
                link={item.link}
                image_out={item.image_out}
                image_over={item.image_over}
                active={active}
                changeActive={this.changeActive}
            />
        );
    }

    createSearchSection() {
        const { manifest } = this.props;
        const { functionOptions, tradesOptions, appearancesOptions } = manifest;

        return (
            <div>
                <PageFilter 
                    search={this.searchByFilter.bind(this)}
                    functionOptions={functionOptions}
                    tradesOptions={tradesOptions}
                    appearancesOptions={appearancesOptions}
                />
            </div>
        )
    }

    searchByFilter(values) {
        const { getReferencesByFilter } = this.props;
        getReferencesByFilter(values);
    }
}

export default ReferencesIndexPage;
