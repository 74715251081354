export const GET_REFERENCES = "GET_REFERENCES";
export const GET_REFERENCES_SUCCESS = "GET_REFERENCES_SUCCESS";

export const GET_MANIFEST = "GET_MANIFEST";
export const GET_MANIFEST_SUCCESS = "GET_MANIFEST_SUCCESS";

export const GET_REFERENCES_BY_FILTER = "GET_REFERENCES_BY_FILTER";
export const GET_REFERENCES_BY_FILTER_SUCCESS = "GET_REFERENCES_BY_FILTER_SUCCESS";

export const OPEN_FILTER_PANEL = "OPEN_FILTER_PANEL";
export const CLOSE_FILTER_PANEL = "CLOSE_FILTER_PANEL";
export const TOGGLE_FILTER_PANEL = "TOGGLE_FILTER_PANEL";