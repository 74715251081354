import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import css from './FormikCheckBoxGroup.module.scss';

class FormikCheckBoxGroup extends Component {

    static propTypes = {
        values: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired,
        error: PropTypes.string,
        title: PropTypes.string,
    }

    static defaultProps = {
        values: [],
        onChange: () => {},
        children: [],
    };

    handleChange = (checkBoxId, event) => {
        const { values, onChange, id, form } = this.props;
        const { setFieldValue } = form;

        const exist = !!values.find(value => value === checkBoxId);
        const newValues = exist ? values.filter(value => value !== checkBoxId) : [...values, checkBoxId];

        onChange(id, newValues);
        setFieldValue(id, newValues);
    };

    handleBlur() {
        const { id, field: { onBlur }} = this.props;
        onBlur(id, true);
    }

    render() {
        return (
            <div className={css.formControl} >
                {this.createFormControlTitle()}
                {this.createFormControlContent()}
                {this.createFormControlError()}
            </div>
        );
    }

    createFormControlTitle() {
        const {
            error,
            title,
            classes,
        } = this.props;

        if (!title) {
            return null;
        }

        return (
            <div style={classNames(classes.title, error ? classes.errorColor : null)}>
                {title}
            </div>
        );
    }

    createFormControlContent() {
        const {
            children,
        } = this.props;

        return React.Children.map(children, this.createFormControlContentChild.bind(this));
    }

    createFormControlContentChild(child) {
        const {
            values,
        } = this.props;

        return React.cloneElement(child, {
            field: {
                value: !!values.find(value => value === child.props.name),
                onBlur: this.handleBlur.bind(this),
                onChange: this.handleChange.bind(this, child.props.name),
            }
        });
    }

    createFormControlError() {
        const {
            field: { name },
            form: { touched, errors },
        } = this.props;

        const errorMsg = getIn(errors, name);
        const touch = getIn(touched, name);
        const hasError = touch && errorMsg !== undefined;

        if (hasError && errorMsg) {
            return (
                <div
                    className={css.errorMsg}
                >
                    {errorMsg}
                </div>
            );
        }

        return null;
    }
}

export default FormikCheckBoxGroup;