import React, { Component } from "react";
import PropTypes from "prop-types";
import css from './Hashtags.module.scss';

class Hashtags extends Component {

  static propTypes = {
    tags_function: PropTypes.string,
    tags_trade: PropTypes.string,
    tags_appearance: PropTypes.string,
  }

  static defaultProps = {
    tags_function: null,
    tags_trade: null,
    tags_appearance: null,
  }

  render() {
    const { 
      tags_function,
      tags_trade,
      tags_appearance,
    } = this.props;

    return (
      <div className={css.hashtagsWrapper}>
        {this.convertStringTags(tags_function)}
        {this.convertStringTags(tags_trade)}
        {this.convertStringTags(tags_appearance)}
      </div>
    );
  }

  convertStringTags(tags) {
    if(!tags) {
      return null;
    }

    return tags.split(', ')
            .map((tag, index) => {
              return (
                <div key={`${tag}_${index}`}>
                  {`#${tag}`}
                </div>
              );
            });
  }
}

export default Hashtags;
