import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";

import ReferencesIndexPage from "./components/pages/references/index/ReferencesIndexPage.container";
import DefaultLayout from "./components/layouts/types/default/DefaultLayout";


function App() {
  return (
    <Provider store={store}>
      <DefaultLayout>
        <div className="App">
          <ReferencesIndexPage />
        </div>
      </DefaultLayout>
    </Provider>
  );
}

export default App;
