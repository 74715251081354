import { connect } from "react-redux";
import compose from "recompose/compose";
import { bindActionCreators } from 'redux';

import ReferencesIndexPage from "./ReferencesIndexPage";
import { getManifest, getReferencesByFilter } from '../../../../redux/actions/actions';

const mapStateToProps = state => {
  return {
    filterPanelIsOpen: state.references.filterPanelIsOpen,
    references: state.references.references,
    filter_references: state.references.filter_references,
    manifest: state.manifest.data
  }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
      getManifest,
      getReferencesByFilter,
    }, 
    dispatch);
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReferencesIndexPage);