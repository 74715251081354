class LocalClient {
  constructor(params = {}) {
    this.store = params.store;
  }

  setStore(store) {
    this.store = store;
  }

  get(url, params = {}) {
    switch (url) {
      case 'manifest.json':
        return Promise.resolve({
            data: {
                "short_name": "React App",
                "name": "Create React App Sample",
                "icons": [
                    {
                    "src": "favicon.ico",
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                    },
                    {
                    "src": "logo192.png",
                    "type": "image/png",
                    "sizes": "192x192"
                    },
                    {
                    "src": "logo512.png",
                    "type": "image/png",
                    "sizes": "512x512"
                    }
                ],
                "start_url": ".",
                "display": "standalone",
                "theme_color": "#000000",
                "background_color": "#ffffff"
            }
        })
      default:
        return Promise.reject({ data: {} });
    }
  }

  post(url) {
    return Promise.resolve({ data: {} });
  }

  put(url, params) {
    return Promise.resolve({ data: {} });
  }

  delete(url, params) {
    return Promise.resolve({ data: {} });
  }

  request(request) {
    if (!request) {
      return Promise.reject({ data: {} });
    }

    const { method, url, data } = request;

    switch (method) {
      case 'GET':
        return this.get(url, data);
      case 'POST':
        return this.post(url, data);
      case 'PUT':
        return this.put(url, data);
      case 'DELETE':
        return this.delete(url, data);
      default:
        return Promise.reject({ data: {} });
    }
  }
}

export default LocalClient;
