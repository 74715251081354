import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import FormikTextField from '../../../formik/formik-text-fields/FormikTextField';
import FormikCheckBoxGroup from '../../../formik/formik-checkbox-group/FormikCheckBoxGroup';
import FormikCheckBoxField from '../../../formik/formik-checkbox-field/FormikCheckBoxField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import shape from "../../../../img/shape.png";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import {
  getValidationSchema,
  initialValues
} from './PageFilter.container';

class PageFilter extends Component {
  static propTypes = {
      search: PropTypes.func,
      functionOptions: PropTypes.array,
      tradesOptions: PropTypes.array,
      appearancesOptions: PropTypes.array,
      filterPanelIsOpen: PropTypes.bool,
  };

  static defaultProps = {
      search: (values) => {},
      functionOptions: [],
      tradesOptions: [],
      appearancesOptions: [],
      filterPanelIsOpen: false,
  };

  state = {
    selectedValues: {
      q: '',
      functions: [], 
      trades: [],
      appearances: [],
    },
  };

  toggleFilterPanel = () => {
    const { toggleFilterPanel } = this.props;
    toggleFilterPanel();
  };

  render() {
    return (
        <Formik
            key="filter-form"
            validationSchema={getValidationSchema}
            initialValues={initialValues}
            onSubmit={this.handleSubmitFilter.bind(this)}
            render={(form) => (
                <Form>
                    <div>
                        {this.createFormContent(form)}
                    </div>
                </Form>
            )}
        />
    );
  }

  createCloseButton() {
    const { filterPanelIsOpen } = this.props;

    if (filterPanelIsOpen) {
      return (
        <button
          className="search-icon-clicked"
          onClick={this.toggleFilterPanel}
          type="button"
        >
          <img src={shape} className="shape" alt="" />
          <FontAwesomeIcon icon={faTimes} className="clicked-x" />
        </button>
      )
    }

    return (
      <button
        className="search-icon"
        onClick={this.toggleFilterPanel}
        type="button"
      ></button>
    );
  }

  createSearchInputSection(form) {
    const { submitForm, values } = form;
    const { search } = this.props;

    return (
      <div className="search-input-wrapper">
        <div>
          <label id="label">Filter by:</label>
        </div>
        <div>
          <Field
              title="Search"
              placeholder="Search keyword"
              type="text"
              name="q"
              component={FormikTextField}
              onChange={(e) => {
                search({ ...values, q: e.target.value });
              }}
          />
        </div>
        <div className="search-loupe-icon">
          <FontAwesomeIcon 
            icon={faSearch} 
            size="sm"
            className="loupe-icon"
            onClick={() => submitForm()}
          />
        </div>
        <div className="search-border">
        </div>
      </div>
    );
  }

  createFormContent(form) {
    return (
      <>
        <div className="search">
          <div className="search-wrap">
            <div>
             {this.createCloseButton()}
            </div>
            <div>
              {this.createSearchInputSection(form)}
            </div>
          </div>
          <div>
            {this.createSelectedFiltersQuerySectiom(form)}
          </div>
        </div>

        {this.createFilterSectiom(form)}
      </>
    );
  }

  createFilterSectiom(form) {
    const { filterPanelIsOpen } = this.props;

    if (!filterPanelIsOpen) {
      return null
    }

    return this.createSearchForm(form);
  }


  handleSubmitFilter(values, formik) {
      const { search } = this.props;

      const { setSubmitting } = formik;
      setSubmitting(false);

      search(values);

      this.setState({ selectedValues: values })
  }

  createSearchForm(form) {
      const { isSubmitting } = form;

      return (
          <div className="search-form-wrapper ">
            <div className="form-checkboxes-wrapper">
                <div className="form-checkboxes-item">
                  <div className="filter-title">
                    Function
                  </div>
                  <div className="filter-content">
                    {this.createFunctionsFilter(form)}
                  </div>
                </div>
                <div className="form-checkboxes-item">
                  <div className="filter-title">
                    Trade
                  </div>
                  <div className="filter-content">
                    {this.createTradesFilter(form)}
                  </div>
                </div>
                <div className="form-checkboxes-item">
                  <div className="filter-title">
                    Appearance
                  </div>
                  <div className="filter-content">
                    {this.createAppearancesFilter(form)}
                  </div>
                </div>
            </div>
  
            <div>
              <button 
                type="submit" 
                className="filter-search-button"
                disabled={isSubmitting}
                >
                  Filter   
              </button>
            </div>
        </div>
      )
  }

  createSelectedFiltersQuerySectiom(form) {

    const { selectedValues } = this.state;
    const { functions, trades, appearances } = selectedValues;
    const { functionOptions, tradesOptions, appearancesOptions } = this.props;

    return (
      <div className="selected-filters-query">
        {functions.map((itemValue, index) => this.createQueryItem(functions, 'functions', selectedValues, functionOptions, form, itemValue, index))}
        {trades.map((itemValue, index) => this.createQueryItem(trades, 'trades', selectedValues, tradesOptions, form, itemValue, index))}
        {appearances.map((itemValue, index) => this.createQueryItem(appearances, 'appearances', selectedValues, appearancesOptions, form, itemValue, index))}
      </div>
    );
  }

  createQueryItem(values, fName, selectedValues, optionsArray, form, itemValue, index) {
    const optionName = optionsArray.find(option => option.value === itemValue)

    return (
      <div 
        key={`${fName}_${itemValue}_${index}`}
        className="query-item"
        onClick={() => this.removeQueryItem(values, fName, itemValue, selectedValues, form)}
      >
        <div className="query-item-label">
          {optionName.label}
        </div>
        <div>
          <FontAwesomeIcon 
            icon={faTimes} 
            size="xs"
            className="query-item-close-icon"
            />
        </div>
      </div>
    );
  }

  removeQueryItem(values, fName, itemValue, selectedValues, form) {
    const { search } = this.props;
    const { setValues } = form;
    const newValues = values.filter(value => value !== itemValue)
    const newSelectedValues = { ...selectedValues, [fName]: newValues };
    setValues(newSelectedValues);
    this.setState({ selectedValues: newSelectedValues });

    search(newSelectedValues);
  }

  createLabel(label, count) {
    return (
      <div>
        <span>
          {label}
        </span>
        <span style={{ color: '#4A4A4A', fontSize: '12px' }}>
          ({count})
        </span>
      </div>
    );
  }

  createFunctionsFilter(form) {
    const { functionOptions } = this.props;
    const { values } = form;

    return (
      <Field
          name='functions'
          component={FormikCheckBoxGroup}
          options={functionOptions}
          values={values.functions}
          id={'functions'}
      >
          {functionOptions.map(({ value, label, count }, index) => (
              <Field
                  id={`functions_${index}`}
                  key={value}
                  label={this.createLabel(label, count)}
                  name={value}
                  component={FormikCheckBoxField}
                  disabled={false}
              />
          ))}
      </Field>
    );
  }

  createTradesFilter(form) {
    const { tradesOptions } = this.props;
    const { values } = form;

    return (
      <Field
          name='trades'
          component={FormikCheckBoxGroup}
          options={tradesOptions}
          values={values.trades}
          id={'trades'}
      >
          {tradesOptions.map(({ value, label, count }, index) => (
              <Field
                  id={`trades_${index}`}
                  key={value}
                  label={this.createLabel(label, count)}
                  name={value}
                  component={FormikCheckBoxField}
                  disabled={false}
              />
          ))}
      </Field>
    );
  }

  createAppearancesFilter(form) {
    const { appearancesOptions } = this.props;
    const { values } = form;
    
    return (
      <Field
          name='appearances'
          component={FormikCheckBoxGroup}
          options={appearancesOptions}
          values={values.appearances}
          id={'appearances'}
      >
          {appearancesOptions.map(({ value, label, count }, index) => (
              <Field
                  id={`appearances_${index}`}
                  key={value}
                  label={this.createLabel(label, count)}
                  name={value}
                  component={FormikCheckBoxField}
                  disabled={false}
              />
          ))}
      </Field>
    );
  }
}

export default PageFilter;
