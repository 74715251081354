import {createStore, applyMiddleware} from 'redux';
import axios from 'axios';
import { multiClientMiddleware  } from 'redux-axios-middleware';
import reducers from "./reducers";

import LocalClient from "./clients/LocalClient";
import ReferencesClient from "./clients/ReferencesClient";

const localClient = new LocalClient();
const referencesClient = new ReferencesClient();

const clients = {
    default: {
      client: axios.create({
         baseURL:'/',
         responseType: 'json',
         headers: {
          "Content-Type": "application/json-patch+json",
          "Access-Control-Allow-Origin": "*"
        },
      })
    },
    references: {
        client: referencesClient,
        options: { withCredentials: true }
    },
    local: {
        client: localClient,
        options: { withCredentials: true }
    }
  }

  const store = createStore(
  reducers,
  applyMiddleware(
    multiClientMiddleware(
        clients,
        { returnRejectedPromiseOnError: true }
      )
  ))

  referencesClient.setStore(store);

export default store