import React, { Component } from "react";
import be from "../../../../../img/be.png";
import v from "../../../../../img//v.png";
import fb from "../../../../../img//fb.png";
import insta from "../../../../../img//insta.png";
import twitter from "../../../../../img//twitter.png";

import "./Footer.css";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <div className="footer-content">
          <div className="copyrights">
            <p>Copyrights Trzask Studio 2019</p>
          </div>
          <div className="social-media-icons">
            <p>Follow us!</p>
            <img className="be" src={be} alt="" />
            <img className="v" src={v} alt="" />
            <img className="fb" src={fb} alt="" />
            <img className="insta" src={insta} alt="" />
            <img className="twitter" src={twitter} alt="" />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
