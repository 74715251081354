import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/reset.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import "../node_modules/font-awesome/css/font-awesome.css";
import "./fonts/suisse_intl_mono-webfont.woff";
import "./fonts/suisse_intl_mono-webfont.woff2";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
